exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-search-js": () => import("./../../../src/pages/blog/search.js" /* webpackChunkName: "component---src-pages-blog-search-js" */),
  "component---src-pages-cirugias-contentful-cirugias-slug-js": () => import("./../../../src/pages/cirugias/{ContentfulCirugias.slug}.js" /* webpackChunkName: "component---src-pages-cirugias-contentful-cirugias-slug-js" */),
  "component---src-pages-cirugias-js": () => import("./../../../src/pages/cirugias.js" /* webpackChunkName: "component---src-pages-cirugias-js" */),
  "component---src-pages-clinica-milito-js": () => import("./../../../src/pages/clinica-milito.js" /* webpackChunkName: "component---src-pages-clinica-milito-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cosmiatria-y-aparatologia-contentful-cosmiatria-y-aparatologia-slug-js": () => import("./../../../src/pages/cosmiatria-y-aparatologia/{ContentfulCosmiatriaYAparatologia.slug}.js" /* webpackChunkName: "component---src-pages-cosmiatria-y-aparatologia-contentful-cosmiatria-y-aparatologia-slug-js" */),
  "component---src-pages-cosmiatria-y-aparatologia-js": () => import("./../../../src/pages/cosmiatria-y-aparatologia.js" /* webpackChunkName: "component---src-pages-cosmiatria-y-aparatologia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-tratamientos-contentful-tratamientos-slug-js": () => import("./../../../src/pages/tratamientos/{ContentfulTratamientos.slug}.js" /* webpackChunkName: "component---src-pages-tratamientos-contentful-tratamientos-slug-js" */),
  "component---src-pages-tratamientos-js": () => import("./../../../src/pages/tratamientos.js" /* webpackChunkName: "component---src-pages-tratamientos-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-profesional-js": () => import("./../../../src/templates/profesional.js" /* webpackChunkName: "component---src-templates-profesional-js" */)
}

